import 'bootstrap';
import 'popper.js';
import 'alpinejs';
import { AsYouType } from 'libphonenumber-js/max';
import 'inputmask';
import '../scss/style.scss';
import 'toastr/toastr.scss';

import toastr from 'toastr';

toastr.options = {
  positionClass: 'toast-top-center',
};

$(document).ready(function () {
  Inputmask('+9 999 999 9999').mask(document.getElementById('inputPhone'));
  $('#intro-video-modal')
    .on('shown.bs.modal', function () {
      const videoFrame = $('#intro-video-modal iframe');

      videoFrame.attr('src', videoFrame.data('src'));
    })
    .on('hidden.bs.modal', function () {
      $('#intro-video-modal iframe').attr('src', '');
    });

  $('#inputPhone')
    .on('focus', function () {
      const value = $(this).val();
      if (value.length > 0 && value[0] !== '+') {
        $(this).val('+' + $(this).val());
        var pos = $(this).val().length;
        if (this.setSelectionRange) {
          this.setSelectionRange(pos, pos);
        } else if (this.createTextRange) {
          var range = this.createTextRange();
          range.collapse(true);
          if (pos < 0) {
            pos = pos + 1;
          }
          range.moveEnd('character', 1);
          range.moveStart('character', 1);
          range.select();
        }
      }
    })
    .on('input', formattPhone)
    .on('paste', formattPhone);

  $('#inquire').on('submit', function (event) {
    event.preventDefault();
    $.post({
      url: `https://api.vizonta.com/inquiry/new`,
      data: { phone: $('#inputPhone').val() },
      success: (data) => {
        if (data) {
          toastr.success('Заявка отправлена');
        }
      },
      error: ({ responseJSON }) => {
        const constraints = (responseJSON?.message || [])[0]?.constraints || { common: 'Ой, что-то пошло не так!' };
        const key = Object.keys(constraints)[0];
        toastr.error(constraints[key]);
      },
      dataType: 'json',
    });
  });
});

function formattPhone() {
  const value = $(this).val();
  const digits = value.replace(/\D/gi, '');
  if (digits) {
    console.log({ digits });
    const asYouType = new AsYouType();
    const newValue = asYouType.input(`+${digits}`);
    const initialTemplate = asYouType.formatter.template || 'xxxxxxxx';
    const template = `+${initialTemplate.substring(1).replace(/x/gi, '9')}`;
    Inputmask(template).mask(document.getElementById('inputPhone'));
    $(this).val(newValue);
  } else {
    $(this).val('+');
  }
}

Number.prototype.format = function (n, x) {
  var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
  return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$& ');
};
